import { puzzleAnswers, checkSolution } from "../../lib/game-helpers";
import { useSteps } from "@chakra-ui/react";
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage } from "../../../common/lib/local-storage";
import { GUESS_HEADLINE_DATA } from "../../lib/constants";
import { RouteContext } from "../../../common/providers/RouteProvider";
import { createContext, useContext, useEffect, useState } from "react";

export const HeadlineStatusContext = createContext();

function HeadlineStatusProvider({ children }) {
  const {
    setIsSaving,
    currentPuzzle
  } = useContext(RouteContext);
  const [gameData, setGameData] = useState(puzzleAnswers);
  const [submittedGuesses, setSubmittedGuesses] = useState([]);
  const [activeLevel, setActiveLevel] = useState(puzzleAnswers[0]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: gameData.length,
  });
  const [solvedGameData, setSolvedGameData] = useState(() => {
    const loadedState = loadGameStateFromLocalStorage(GUESS_HEADLINE_DATA);
    
    if(loadedState?.activeStep !== undefined) {
      setActiveStep(loadedState?.activeStep)
    }
    if (Array.isArray(loadedState?.submittedGuesses)) {
      setSubmittedGuesses(loadedState.submittedGuesses);
    }

    if (Array.isArray(loadedState?.solvedGameData)) {
      return loadedState.solvedGameData;
    }
    
    return [];
  });
  

  useEffect(function () {
    saveGameStateToLocalStorage(GUESS_HEADLINE_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setActiveLevel(activeStep >= 4 ? puzzleAnswers[3] : puzzleAnswers[activeStep]);
    setIsSaving(true)
  }, [activeStep])
  
  useEffect(function () {
    
    const { guess, tagline, yearDifference, level, color } = checkSolution({gameData, submittedGuesses, activeStep})
    
    if(guess) {
      if(solvedGameData.findIndex(x => x.guess.levelId === guess.levelId) === -1) {
        setSolvedGameData([...solvedGameData, { guess, tagline, yearDifference, level, color }])
      }
      setIsGameOver(true);
    }
    if(yearDifference && yearDifference === 0) {
      setIsGameWon(true);
    }
    saveGameStateToLocalStorage(GUESS_HEADLINE_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setIsSaving(true)
  }, [submittedGuesses])

  useEffect(function () {
    saveGameStateToLocalStorage(GUESS_HEADLINE_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setIsSaving(true)
  }, [solvedGameData])

  const nextTiktok = function() {
    setIsGameOver(false);
    setIsGameWon(false);
    setActiveStep(activeStep + 1);
  }

  return (
    <HeadlineStatusContext.Provider
      value={{ gameData, submittedGuesses, setSubmittedGuesses, activeLevel, setActiveLevel, activeStep, setActiveStep, nextTiktok, isGameOver, isGameWon, solvedGameData }}
    >
      {children}
    </HeadlineStatusContext.Provider>
  );
}

export default HeadlineStatusProvider;
