import { ListItem, UnorderedList } from "@chakra-ui/react";

function HeadlineInfo() {
  return (
    <>
      <br />
      You're always up to date with the latest news. Heck, you probably knew about January 6th before it actually happened (this is a joke FBI). Let's see how well you know your news! 
      <br />
      <br />
      For this puzzle, you will be presented with 4 news articles. All you have
      to do is guess the year each article was published in. Sounds easy, right?
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>Read the article (duh)</ListItem>
        <ListItem>Adjust the slider to the desired year</ListItem>
        <ListItem>Select a month for extra points!</ListItem>
        <ListItem>Click Submit to make a guess</ListItem>
      </UnorderedList>
      <br />
      After each guess I will reveal the correct answer and how close you were
      (you will be awarded points based on this).
      <br />
      <br />
      <i>All the articles were taken from The New York Times archive, of course.</i>
    </>
  );
}

export default HeadlineInfo;
