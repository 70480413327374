import { puzzleAnswers, checkSolution } from "../../lib/game-helpers";
import { useSteps } from "@chakra-ui/react";
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage } from "../../../common/lib/local-storage";
import { GUESS_QUIZ_DATA } from "../../lib/constants";
import { RouteContext } from "../../../common/providers/RouteProvider";
import { createContext, useContext, useEffect, useState } from "react";

export const QuizStatusContext = createContext();

function QuizStatusProvider({ children }) {
  const {
    setIsSaving,
    currentPuzzle
  } = useContext(RouteContext);
  const [gameData, setGameData] = useState(puzzleAnswers);
  const [submittedGuesses, setSubmittedGuesses] = useState([]);
  const [activeLevel, setActiveLevel] = useState(puzzleAnswers[0]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: gameData.length,
  });
  const [solvedGameData, setSolvedGameData] = useState(() => {
    const loadedState = loadGameStateFromLocalStorage(GUESS_QUIZ_DATA);
    
    if(loadedState?.activeStep !== undefined) {
      setActiveStep(loadedState?.activeStep)
    }
    if (Array.isArray(loadedState?.submittedGuesses)) {
      setSubmittedGuesses(loadedState.submittedGuesses);
    }

    if (Array.isArray(loadedState?.solvedGameData)) {
      return loadedState.solvedGameData;
    }
    
    return [];
  });
  

  useEffect(function () {
    saveGameStateToLocalStorage(GUESS_QUIZ_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setActiveLevel(activeStep >= 10 ? puzzleAnswers[9] : puzzleAnswers[activeStep]);
    setIsSaving(true)
  }, [activeStep])
  
  useEffect(function () {
    
    const { guess, tagline, isCorrect, level, color } = checkSolution({gameData, submittedGuesses, activeStep})
    
    if(guess) {
      if(solvedGameData.findIndex(x => x.guess.levelId === guess.levelId) === -1) {
        setSolvedGameData([...solvedGameData, { guess, tagline, isCorrect, level, color }])
      }
      setIsGameOver(true);
    }
    if(isCorrect) {
      setIsGameWon(true);
    }
    saveGameStateToLocalStorage(GUESS_QUIZ_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setIsSaving(true)
  }, [submittedGuesses])

  useEffect(function () {
    saveGameStateToLocalStorage(GUESS_QUIZ_DATA, {solvedGameData, submittedGuesses, activeStep}, currentPuzzle.session)
    setIsSaving(true)
  }, [solvedGameData])

  const nextTiktok = function() {
    setIsGameOver(false);
    setIsGameWon(false);
    setActiveStep(activeStep + 1);
  }

  return (
    <QuizStatusContext.Provider
      value={{ gameData, submittedGuesses, setSubmittedGuesses, activeLevel, setActiveLevel, activeStep, setActiveStep, nextTiktok, isGameOver, isGameWon, solvedGameData }}
    >
      {children}
    </QuizStatusContext.Provider>
  );
}

export default QuizStatusProvider;
