import ConnectionsInfo from "../../guess-connections/components/ConnectionsInfo";
import ConnectionsResults from "../../guess-connections/components/ConnectionsResults";
import GameStatusProvider from "../../guess-connections/providers/GameStatusProvider";
import PuzzleDataProvider from "../../guess-connections/providers/PuzzleDataProvider";
import GeoguessrInfo from "../../guess-country/components/GeoguessrInfo";
import GeoguessrResults from "../../guess-country/components/GeoguessrResults";
import CountryStatusProvider from "../../guess-country/providers/CountryStatusProvider";
import HeadlineInfo from "../../guess-headline/components/HeadlineInfo";
import HeadlineResults from "../../guess-headline/components/HeadlineResults/headlineResults";
import HeadlineStatusProvider from "../../guess-headline/providers/HeadlineStatusProvider";
import QuizInfo from "../../guess-quiz/components/QuizInfo";
import QuizResults from "../../guess-quiz/components/QuizResults";
import QuizStatusProvider from "../../guess-quiz/providers/QuizStatusProvider";
import RecipeInfo from "../../guess-recipe/components/RecipeInfo";
import RecipeResults from "../../guess-recipe/components/RecipeResults/recipeResults";
import RecipeStatusProvider from "../../guess-recipe/providers/RecipeStatusProvider";
import SpellingBeeInfo from "../../guess-spelling-bee/components/SpellingBeeInfo";
import SpellingBeeResults from "../../guess-spelling-bee/components/SpellingBeeResults";
import SpellingStatusProvider from "../../guess-spelling-bee/providers/SpellingStatusProvider/SpellingStatusProvider";
import ValorantGuesserInfo from "../../guess-valorant-map/components/ValorantGuesserInfo";
import ValorantGuesserResults from "../../guess-valorant-map/components/ValorantGuesserResults";
import ValorantStatusProvider from "../../guess-valorant-map/providers/ValorantStatusProvider";


export const data = {
    puzzles: [
        {
            puzzleId: -1,
            name: "Invalid"
        },
        {
            puzzleId: 0,
            name: "Introduction"
        },
        {
            puzzleId: 1,
            name: "Headlines",
            info: <HeadlineInfo />,
            results: <HeadlineStatusProvider><HeadlineResults isFinal={true} /></HeadlineStatusProvider>
        },
        {
            puzzleId: 2,
            name: "U.S. states",
            info: <GeoguessrInfo />,
            results: <CountryStatusProvider><GeoguessrResults isFinal={true} /></CountryStatusProvider>
        },
        {
            puzzleId: 3,
            name: "Spelling bee",
            info: <SpellingBeeInfo />,
            results: <SpellingStatusProvider><SpellingBeeResults isFinal={true} /></SpellingStatusProvider>
        },
        {
            puzzleId: 4,
            name: "Connections",
            info: <ConnectionsInfo />,
            results: <PuzzleDataProvider><GameStatusProvider><ConnectionsResults isFinal={true} /></GameStatusProvider></PuzzleDataProvider>
        },
        {
            puzzleId: 5,
            name: "Valo Guesser",
            info: <ValorantGuesserInfo />,
            results: <ValorantStatusProvider><ValorantGuesserResults isFinal={true} /></ValorantStatusProvider>
        },
        {
            puzzleId: 6,
            name: "Desserts",
            info: <RecipeInfo />,
            results: <RecipeStatusProvider><RecipeResults isFinal={true} /></RecipeStatusProvider>
        },
        {
            puzzleId: 7,
            name: "Pop quiz!",
            info: <QuizInfo />,
            results: <QuizStatusProvider><QuizResults isFinal={true} /></QuizStatusProvider>
        },
        {
            puzzleId: 8,
            name: "End"
        },
    ]
};
