import { RouteContext } from "../../providers/RouteProvider";
import { lazy, Suspense, useContext } from "react";
import 'react-morphing-modal/dist/ReactMorphingModal.css';

const Loading = lazy(() => import("../Loading"))
const Invalid = lazy(() => import("../Invalid"))
const Intro = lazy(() => import("../Intro"))
const HeadlineApp = lazy(() => import("../../../guess-headline/components/HeadlineApp"))
const StatesApp = lazy(() => import("../../../guess-country/components/StatesApp"))
const SpellingBeeApp = lazy(() => import("../../../guess-spelling-bee/components/SpellingBeeApp"))
const ConnectionsApp = lazy(() => import("../../../guess-connections/components/ConnectionsApp"))
const ValorantGuesserApp = lazy(() => import("../../../guess-valorant-map/components/ValorantGuesserApp"))
const RecipeApp = lazy(() => import("../../../guess-recipe/components/RecipeApp"))
const QuizApp = lazy(() => import("../../../guess-quiz/components/QuizApp"))
const End = lazy(() => import("../End"))
const Saving = lazy(() => import("../Saving"))

function Common() {
  const { currentPuzzle, isSaving } = useContext(RouteContext);
  return (
    <Suspense fallback={<></>}>
      {currentPuzzle.loading && (
        <Loading />
      )}
      {currentPuzzle.puzzleId === -1 && !currentPuzzle.loading && (
        <Invalid />
      )}
      {currentPuzzle.puzzleId === 0 && !currentPuzzle.loading && (
        <Intro />
      )}
      {currentPuzzle.puzzleId === 1 && !currentPuzzle.loading && (
        <HeadlineApp />
      )}
      {currentPuzzle.puzzleId === 2 && !currentPuzzle.loading && (
        <StatesApp />
      )}
      {currentPuzzle.puzzleId === 3 && !currentPuzzle.loading && (
        <SpellingBeeApp />
      )}
      {currentPuzzle.puzzleId === 4 && !currentPuzzle.loading && (
        <ConnectionsApp />
      )}
      {currentPuzzle.puzzleId === 5 && !currentPuzzle.loading && (
        <ValorantGuesserApp />
      )}
      {currentPuzzle.puzzleId === 6 && !currentPuzzle.loading && (
        <RecipeApp />
      )}
      {currentPuzzle.puzzleId === 7 && !currentPuzzle.loading && (
        <QuizApp />
      )}
      {currentPuzzle.puzzleId === 8 && !currentPuzzle.loading && (
        <End />
      )}
      {isSaving && <Saving />}
    </Suspense>
  );
}

export default Common;
