export const data = {
  levels: [
    {
      levelId: 1,
      center: {
        lat: 46.062,
        lng: -118.348,
      },
      state: "Washington",
    },
    {
      levelId: 2,
      center: {
        lat: 24.675,
        lng: -81.37,
      },
      state: "Florida",
    },
    {
      levelId: 3,
      center: {
        lat: 39.3182815,
        lng: -79.4867181,
      },
      state: "West Virginia",
    },
    {
      levelId: 4,
      center: {
        lat: 44.6812028,
        lng: -93.6397938,
      },
      state: "Minnesota",
    },
  ],
};
