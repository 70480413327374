import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Common from "./common/components/Common/common";
import RouteProvider from "./common/providers/RouteProvider";
import Header from "./common/components/Header";
import "./globals.css";

const theme = extendTheme({
  initialColorMode: 'dark',
  fonts: {
    heading: `'Spacemono', sans-serif`,
    body: `'Spacemono', sans-serif`,
  },
})

export default function Home() {
  return (
    <ChakraProvider theme={theme}>
      {/* <main className="flex min-h-screen flex-col items-center"> */}
        <RouteProvider>
          <Header></Header>
          <Common></Common>
        </RouteProvider>
      {/* </main> */}
    </ChakraProvider>
  );
}
