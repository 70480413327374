import {
  Button,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import "./headlineResults.css";
import { HeadlineStatusContext } from "../../providers/HeadlineStatusProvider";
import {
  generateEmojiGridForHeadline,
  getHeadlinePoints,
} from "../../lib/game-helpers";
import { motion, useIsPresent } from "framer-motion";
import { RouteContext } from "../../../common/providers/RouteProvider";
import { shareStatus } from "../../../common/lib/share-game";
import { useContext } from "react";

function HeadlineResults({ close, isFinal }) {
  const { nextPuzzle } = useContext(RouteContext);
  const isPresent = useIsPresent();
  const {
    solvedGameData,
  } = useContext(HeadlineStatusContext);
  const { points, maxPoints, title } = getHeadlinePoints({ solvedGameData });
  const toast = useToast();
  function handleShareToClipboard() {
    toast({
      title: "Copied to clipboard",
      description: "But why?",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  }
  function handleShareFailure() {
    toast({
      title: "Could not copy to clipboard",
      description: "Please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  }
  const copyToClipboard = () => {
    shareStatus(
      "Headlines",
      generateEmojiGridForHeadline({ solvedGameData }),
      "Total score: " + points + " / " + maxPoints,
      handleShareToClipboard,
      handleShareFailure
    );
  };
  const handleNextPuzzle = () => {
    if(typeof close === "function") {
      close();
    }
    nextPuzzle();
  }
  return (
    <div className="results-screen">
      <Heading mb="19">{title}</Heading>
      <Heading size={"md"}>Headlines</Heading>
      <br />
      {generateEmojiGridForHeadline({ solvedGameData }).map((row, index) => (
        <span key={index}>
          {row}
          <br />
        </span>
      ))}
      <br />
      <Heading size={"sm"}>
        {"Total score: " + points + " / " + maxPoints}
      </Heading>
      <Stack direction={["column", "row"]} pt="10">
        <Button
          w="100%"
          onClick={copyToClipboard}
          colorScheme="white"
          variant="outline"
          size="lg"
        >
          Copy to clipboard
        </Button>
      {!isFinal && (
        <Button
          w="100%"
          onClick={handleNextPuzzle}
          colorScheme="white"
          variant="outline"
          size="lg"
        >
          Next puzzle
        </Button>
      )}
      </Stack>
    </div>
  );
}

export default HeadlineResults;
