import { ListItem, UnorderedList } from "@chakra-ui/react";

function RecipeInfo() {
  return (
    <>
      <br />
      You have such a sweet tooth and have baked many a dessert before, so it would only make sense for me to put your baking knowledge to the test!
      <br />
      <br />
      For this puzzle, you will first be presented with a list of ingredients and you’ll have to guess the correct dessert. You will be able to see the full instructions and a picture of the dessert after making one and two wrong guesses, respectively.
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>
        Read the list of ingredients
        </ListItem>
        <ListItem>
        Select a dessert from the list (there’s over 900 to choose from!)
        </ListItem>
        <ListItem>Click Submit</ListItem>
        <ListItem>After your first wrong guess, the Instructions tab will unlock, so you will have more information for your next guess.</ListItem>
        <ListItem>After your second wrong guess, the Picture tab will unlock. If you don’t know the dessert by picture… well, gg.</ListItem>
      </UnorderedList>
      <br />
      I will reveal the correct answer after your fourth wrong guess. You will be awarded points based on how many guesses it took you to choose the right dessert (or none if you never did).
    </>
  );
}

export default RecipeInfo;
