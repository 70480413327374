import { ListItem, UnorderedList } from "@chakra-ui/react";

function ValorantGuesserInfo() {
  return (
    <>
      <br />
      Let's be honest: you're already great at Valorant, but part of becoming an even better player is knowing the game inside and out. Every corner of it!
      <br />
      <br />
      For this puzzle, you will be presented with 4 screenshots I took while in a Valorant custom game. Can you find out exactly where each screenshot was taken from?
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>
        Analyze the picture
        </ListItem>
        <ListItem>
        Once you’re ready to guess, click “Make a guess” (you will be able to check the picture again before making a final guess)
        </ListItem>
        <ListItem>Select a map, then click on where you think I was standing when I took the screenshot</ListItem>
        <ListItem>Click Submit</ListItem>
      </UnorderedList>
      <br />
      After each guess I will reveal the correct map and location. You will be awarded points based on how close you were (guessing the map incorrectly will award no points).
    </>
  );
}

export default ValorantGuesserInfo;
