import { range } from "../../guess-connections/lib/utils";
import { data as RECIPES } from "./data";

export function getPuzzleData({ currentLevel }) {
  return RECIPES.levels.find((x) => x.levelId === currentLevel);
}

export function isGuessCorrect({ gameData, guessCandidate }) {
  let level = gameData.find((x) => x.levelId === guessCandidate.levelId);
  let isGuessCandidateCorrect = false;
  if(guessCandidate.dessert.toUpperCase() === level.dessert.toUpperCase()) {
    isGuessCandidateCorrect = true;
  }
  return {
    isGuessCandidateCorrect
  };
}

export function checkSolution({ gameData, submittedGuesses, activeStep }) {
  let levelId = activeStep + 1;
  let guesses = submittedGuesses.filter((x) => x.levelId === levelId);
  let tagline = "";
  let color = "red";
  if (!guesses || guesses.length === 0) return {};
  let level = gameData.find((x) => x.levelId === levelId);
  let isCorrect = false;
  let mistakes = 0;
  for(var guess of guesses) {
    if(guess.dessert.toUpperCase() === level.dessert.toUpperCase()) {
      isCorrect = true;
      guess.isCorrect = true;
      guess.color = "green"
    } else {
      guess.isCorrect = false;
      guess.color = "red"
      mistakes++;
    }
  }
  return {
    guesses,
    tagline,
    isCorrect,
    mistakes,
    level,
    color,
  };
}

export function generateEmojiGridForHeadline({ solvedGameData }) {
  let emojiArray = [];
  for (var data of solvedGameData) {
    let level = "Recipe " + data.level.levelId + " ";
    let guessNumber = 4;
    for (var guess of data.guesses) {
      switch (guess.color) {
        case "red":
          level += `🟥`;
          break;
        default:
          level += `✅`;
          break;
         
      }
      guessNumber--;
    }
    if(guessNumber > 0) {
      for(var number of range(guessNumber)) {
        level += `🟩`
      }
    }
    
    emojiArray.push(level);
  }
  return emojiArray;
}

export function getRecipePoints({solvedGameData}) {
  let maxPoints = solvedGameData.length * 1250;
  let points = 5000;
  for (var data of solvedGameData) {
    for(var guess of data.guesses) {
      if(!guess.isCorrect) {
        points -= 312.5
      }
    }
  }
  points = Math.round(points)
  let title = "";
  if(points === 5000) {
    title = "Perfect!"
  } else if (points >= 3500 ) {
    title = "Well done!"
  } else if (points >= 2000 ) {
    title = "Not bad!"
  } else {
    title = "You tried..."
  }
  return {
    points, maxPoints, title
  }
}

export const puzzleAnswers = RECIPES.levels;
