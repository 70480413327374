
export const CONNECTION_GAMES = [
  [
    {
      category: "WHITE",
      words: ["BONE", "IVORY", "PORCELAIN", "EGGSHELL"],
      difficulty: 1,
    },
    {
      category: "WEAPONS OFTEN FOUND IN FLAGS >:)",
      words: ["TRIDENT", "CLUB", "DAGGER", "STAFF"],
      difficulty: 2,
    },
    {
      category: "WRESTLING MOVES",
      words: ["SPEAR", "CUTTER", "SUPLEX", "STUNNER"],
      difficulty: 3,
    },
    {
      category: "WORDS CONTAINING VALORANT AGENTS",
      words: ["ISOLATION", "PHENOMENON", "PASSAGE", "CRAZE"],
      difficulty: 4,
    },
  ]
];

export const TAGLINES = [
  // {
  //   date: '2023-11-07',
  //   text: 'THERE IS A THEME YALL FIGURE IT OUT'
  // }
]