import CryptoJS from "crypto-js";
import bcrypt from "bcryptjs";
import { ACCESS_TOKEN, REWARD, REWARD_KEY } from "./constants";

export function validateToken({token}) {
    if(token) {
        let valid = bcrypt.compareSync(token, ACCESS_TOKEN)
        return valid
    }
    return false;
}

export function validateUsername({username}) {
    if(username) {
        let valid = bcrypt.compareSync(username, REWARD_KEY)
        if(valid) {
            let code = decrypt(REWARD, username)
            return code
        }
    }
    return null
    // if(token) {
    //     let valid = bcrypt.compareSync(token, ACCESS_TOKEN)
    //     return valid
    // }
    // return false;
}

export function newGuid() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

export const hashData = (data) => {
    var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(data, salt);
    return hash;
}

export const encrypt = (data, passphrase) => {
    return CryptoJS.AES.encrypt(data, passphrase)
  }
  
 export const decrypt = (data, passphrase) => {
    const decrypted = CryptoJS.AES.decrypt(data, passphrase)
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  export async function smileyFace() {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data;
  }