export const data = {
  levels: [
    {
      levelId: 1,
      dessert: "Blondie",
      ingredients: [
        "1 cup unsalted butter",
        "1 ¼ cup brown sugar",
        "½ cup sugar",
        "2 large eggs + 1 egg yolk (room temperature preferred)",
        "2 teaspoons vanilla extract",
        "2 ¼ cups all-purpose flour",
        "2 teaspoons cornstarch",
        "½ teaspoon baking powder",
        "1 teaspoon salt",
        "⅔ cup white chocolate chips",
        "1 cup chopped walnuts",
      ],
      instructions: [
        "Preheat oven to 350F and line a 13x9 pan with parchment paper",
        "Combine melted butter and sugar in a large bowl and stir well",
        "Add eggs, egg yolk, and vanilla extract and stir until completely combined. Set aside",
        "In a separate bowl, whisk together flour, cornstarch, baking powder, and salt",
        "Gradually stir dry ingredients into wet until completely combined",
        "Fold in white chocolate chips and nuts, if using",
        "Spread the batter into prepared pan and transfer to oven",
        "Bake on 350F for 25-30 minutes or until a toothpick inserted in the center comes out clean or with a few fudgy crumbs",
        "Allow to cool before cutting and enjoying",
      ],
    },
    {
      levelId: 2,
      dessert: "Tiramisu",
      ingredients: [
        "¼ cup Dutch Processed cocoa powder",
        "1 cup brewed espresso (optional to add a few tablespoons of dark rum)",
        "1 tablespoon vanilla extract",
        "5 large egg yolks, cold",
        "½ cup white sugar",
        "¼ teaspoon kosher salt",
        "16 ounces mascarpone cheese, chilled",
        "1 ¾ cups heavy cream, chilled",
        "2-3 dozen hard ladyfinger cookies",
      ],
      instructions: [
        "Whisk together the cocoa powder, espresso and vanilla in a bowl and set aside",
        "In a mixer or using a hand mixer, beat the egg yolks and sugar until pale and thick, about 5 minutes. Add in the salt, mascarpone cheese and continue to whip until smooth. Add the chilled cream and continue to whip, until light, creamy, smooth and can hold a soft peak",
        "Dunk each ladyfinger in the espresso mixture to absorb the liquid and start to line a 7x11 oval baking dish with the cookies. Top the first layer of cookies with ½ of the whipped mascarpone mixture and use the back of a metal spoon to spread it into an even layer. Dust with cocoa powder. Repeat for another layer and then finish by dusting with cocoa powder on top. Cover with plastic wrap and chill for at least 2 hours before serving",
      ],
    },
    {
      levelId: 3,
      dessert: "Dango",
      ingredients: [
        "½ block of silken soft tofu",
        "¾ cup glutinous rice flour",
        "¼ cup white sugar",
        "¼ tsp strawberry powder",
        "¼ tsp matcha powder",
        "5–6 bamboo skewer sticks",
      ],
      instructions: [
        "Weigh the rice flour, silken tofu and sugar and then add Into a large bowl. Mix well using your hands. Using a kneading motion until a dough forms.",
        "Divide the dough into three equal parts.",
        "Sift in the strawberry powder into one portion of the dough and knead until it forms a uniform pink colour.",
        "Sift in the matcha powder into another portion of the dough and knead well until it turns green.",
        "Divide each ball of dough into 5-6 balls. I weigh each ball to be around 14-15 grams each, this yields 5 skewers. For smaller balls, make them into 10-12 grams each.",
        "Bring a pot of water to a boil and add in the [REDACTED] balls. Leave the [REDACTED] balls to cook. Remove the [REDACTED] balls when they rise to the top of the water. Scoop them out using a strainer ladle and place into a bowl of ice cold water to stop them from cooking further.",
        "Add one green [REDACTED] ball onto a skewer. Follow with one white and one pink [REDACTED] ball. Repeat for all 5-6 skewers then enjoy!"
      ],
    },
    {
      levelId: 4,
      dessert: "Birthday cake",
      ingredients: [
        "2 ¼ cups all-purpose flour",
        "1 ½ cups sugar",
        "½ cup unsalted butter at room temperature",
        "3 teaspoons baking powder",
        "1 teaspoon salt",
        "1 teaspoon vanilla",
        "1 ½ cups milk (please use almond milk for the sake of Chico's belly!)",
        "3 eggs",
        "Lots of sprinkles",
      ],
      additional_ingredients: [
        {
          group: "Buttercream icing",
          ingredients: [
            "2 sticks butter at room temperature",
            "4 teaspoons butter flavoring",
            "6 cups confectioner's sugar",
            "4-8 tablespoons milk",
          ],
        },
      ],
      instructions: [
        "Preheat oven to 350 and spray two 9-inch cake pans liberally with cooking spray, or grease and flour.",
        "Combine sugar and butter in a large bowl and cream together with an electric mixer.",
        "In a medium-sized bowl, stir together the flour, baking powder, and salt with a spoon. In a separate bowl, stir together the eggs, milk, and vanilla until well combined.",
        "Add half of the wet mixture and the flour mixture to the creamed butter and mix until well combined. Add the remaining and continue mixing until fully blended and smooth, about 2 to 3 minutes.",
        "Add the sprinkles and gently stir them using a spoon, once the cake batter is fully blended.",
        "Divide the cake mix evenly among the two cake pans. Bake for 30 to 35 minutes, or until lightly browned and center springs back when lightly pressed. Remove from oven and allow to cool in pans for 10 minutes before turning out to cool completely.",
      ],
      additional_instructions: [
        {
          group: "Buttercream icing",
          instructions: [
            "In a large bowl, cream the butter and about 1 cup of confectioner's sugar with an electric mixer until light and fluffy. Add the butter flavoring, half of the milk, and the remaining sugar, and beat again until smooth and creamy, scraping down the sides and adding a little extra milk if needed.",
            "Ice the cooled cake and enjoy!",
          ],
        },
      ],
    },
  ],
};
