export const data = {
    levels: [
      {
        levelId: 1,
        map: "Lotus",
        coordinates: {
          top: 45,
          left: 5,
        },
      },
      {
        levelId: 2,
        map: "Ascent",
        coordinates: {
          top: 60,
          left: 52,
        },
      },
      {
        levelId: 3,
        map: "Split",
        coordinates: {
          top: 24,
          left: 36,
        },
      },
      {
        levelId: 4,
        map: "Sunset",
        coordinates: {
          top: 16,
          left: 33,
        },
      },
    ],
  }