export const data = {
    levels: [
      {
        levelId: 1,
        year: 1997,
        month: "March",
        title: "Short Films Face Long Odds for an Oscar",
        author: "Peter M. Nichols",
        description:
          "ANTONIO URRUTIA is coming from Mexico to go to the Oscars. ''I'm excited about meeting Juliette Binoche and Joel Coen, people like that,'' he said. ''I'm going to bring my camera.''<br/><br/>When the Academy Awards ceremony takes place tomorrow night (ABC at 9 P.M.), Ms. Binoche, one of three actors nominated for ''The English Patient,'' and Mr. Coen, the director of the Minnesota murder story ''Fargo,'' could win Oscars. So could Tom Cruise, Milos Forman, Diane Keaton, Arthur Miller and various other notables whom Mr. Urrutia would dearly love to photograph before or after the presentations at the Shrine Auditorium in Los Angeles.",
      },
      {
        levelId: 2,
        year: 2019,
        month: "May",
        title:
          "Trump Accuses Mueller of a Personal Vendetta as Calls for Impeachment Grow",
        author: "Peter Baker and Eileen Sullivan",
        description:
          "COLORADO SPRINGS — President Trump lashed out angrily at Robert S. Mueller III on Thursday, accusing him of pursuing a personal vendetta as Mr. Trump sought to counter increasing calls among Democrats for his impeachment.<br/><br/>A day after Mr. Mueller, the special counsel, spoke out for the first time and refused to exonerate the president, Mr. Trump dismissed the Mueller investigation as hopelessly tarnished and expressed aggravation that he could not shake allegations of wrongdoing that have dogged him since the early days of his administration.",
      },
      {
        levelId: 3,
        year: 2010,
        month: "July",
        title: "As Cameron and Obama Meet, BP Will Be Top Issue",
        author: "John F. Burns",
        description:
          "LONDON — On the eve of a White House meeting with President Obama, Prime Minister David Cameron on Monday stepped into the furor over BP’s lobbying for a prisoner-transfer agreement between Britain and Libya by saying he considered the release of the convicted Lockerbie bomber from a Scottish prison last year to be “completely and utterly wrong.”<br/><br/>Ten weeks after taking office, Mr. Cameron is making his first visit to the United States as prime minister. He and Mr. Obama have a ledger of issues to discuss, including the Cameron government’s decision to set an end date of 2015 for Britain’s combat role in Afghanistan; a radical British deficit-reduction plan that critics have said risks pushing the country toward a double-dip recession; and the controversy that has built around BP, Britain’s largest company, since the Gulf of Mexico oil spill.",
      },
      {
        levelId: 4,
        year: 1986,
        month: "May",
        title: "DOMINICAN, TRAILING IN VOTE, ASSERTS VICTORY",
        author: "Joseph B. Treaster, Special To the New York Times",
        description:
          "Former President Joaquin Balaguer widened his lead to 35,000 votes in the official count in the Dominican presidential election today, but his closest rival, Jacobo Majluta Azar, declared himself the winner and called for a recount.<br/><br/>The leaders of the armed forces moved quickly to declare full confidence in the honesty of the electoral process.<br/><br/>Mr. Majluta, the 51-year-old President of the Senate, said in a press conference that his computers showed him winning the election, adding, ''the triumph is ours and nobody is going to take it away from us.''",
      },
    ],
  }