import {
  addDays,
  differenceInDays,
  formatISO,
  parseISO,
  startOfDay,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import React from "react";
import queryString from "query-string";

import { CONNECTION_GAMES, TAGLINES } from "./data";
import { MAX_MISTAKES, PRODUCTION_URL, LAST_DAY } from "./constants";
//import { loadGameStateFromLocalStorage } from "./local-storage";
//import { getFirestoreData } from "./firestore";

export const getToday = () => startOfToday();
export const getYesterday = () => startOfYesterday();

// October 2023 Game Epoch
// https://stackoverflow.com/questions/2552483/why-does-the-month-argument-range-from-0-to-11-in-javascripts-date-constructor
export const firstGameDate = new Date(2024, 6, 8);
export const periodInDays = 1;

export const getLastGameDate = (today) => {
  const t = startOfDay(today);
  let daysSinceLastGame = differenceInDays(t, firstGameDate) % periodInDays;
  return addDays(t, -daysSinceLastGame);
};

export const getNextGameDate = (today) => {
  return addDays(getLastGameDate(today), periodInDays);
};

export const isValidGameDate = (date) => {
  if (date < firstGameDate || date > getToday()) {
    return false;
  }

  return differenceInDays(firstGameDate, date) % periodInDays === 0;
};

export const getIndex = (gameDate) => {
  let start = firstGameDate;
  let index = -1;
  do {
    index++;
    start = addDays(start, periodInDays);
  } while (start <= gameDate);

  return index;
};

export const getPuzzleOfDay = (index) => {
  if (index < 0) {
    throw new Error("Invalid index");
  }
  //return getFirestoreData(index);
  return CONNECTION_GAMES[index % CONNECTION_GAMES.length];
};

export const getSolution = (gameDate) => {
  const nextGameDate = getNextGameDate(gameDate);
  const index = getIndex(gameDate);
  const puzzleOfTheDay = getPuzzleOfDay(index);
  const tagline = getTaglines(gameDate);
  return {
    puzzleAnswers: puzzleOfTheDay,
    puzzleGameDate: gameDate,
    puzzleIndex: index,
    dateOfNextPuzzle: nextGameDate.valueOf(),
    puzzleTagline: tagline
  };
};

export const getTaglines = (gameDate) => {
  let date = "";
  for(var item of TAGLINES) {
    date = startOfDay(parseISO(item.date));
    if(gameDate.toString() == date.toString()) return item.text;
  }
  return null;
}

export const getGameDate = () => {
  if (getIsLatestGame()) {
    return getToday();
  }

  const parsed = queryString.parse(window.location.search);
  try {
    const d = startOfDay(parseISO(parsed.d?.toString()));
    if (d >= getToday() || d < firstGameDate) {
      setGameDate(getToday());
      return getToday();
    }
    return d;
  } catch (e) {
    return getToday();
  }
};

export const setGameDate = (d) => {
  try {
    if (d < getToday()) {
      window.location.href = PRODUCTION_URL + "/?d=" + formatISO(d, { representation: "date" });
      return;
    }
  } catch (e) {
  }
  window.location.href = PRODUCTION_URL;
};

export const getIsLatestGame = () => {
  // https://github.com/cwackerfuss/react-wordle/pull/505
  const parsed = queryString.parse(window.location.search);
  return parsed === null || !("d" in parsed);
};

export const getArchiveDates = () => {
  let dates = [];
  let lastDay = startOfDay(parseISO(LAST_DAY));
  for(var i = 0; i <= CONNECTION_GAMES.length; i++) {
    let date = addDays(firstGameDate, i);
    let dateISO = formatISO(addDays(firstGameDate, i),  { representation: "date" });
    let result = localStorage.getItem("gameState" + dateISO);
    if(date <= getToday() && date <= lastDay) {
      dates.push({
        id: i,
        date: dateISO,
        result: getArchiveGameStatus(result ? JSON.parse(result) : null)
      })
    }
    
  }
  return dates;
}

export const getArchiveGameStatus = (game) => {
  if(!game) return "PENDING";
  if(game.solvedGameData.length == 4) {
    return "WON";
  }
  if(game.submittedGuesses.length == MAX_MISTAKES) {
    return "LOST";
  }
  if(game.submittedGuesses.length > 0) {
    return "STARTED";
  }
  return "PENDING";
  
}

export const { puzzleAnswers, puzzleGameDate, puzzleIndex, dateOfNextPuzzle, puzzleTagline } =
  getSolution(getGameDate());
