// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { ENABLE_FIREBASE } from "./constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC53CYcDnKRAjHquukObW-pcw9kHeC60n0",
  authDomain: "precise-formula-426217-g5.firebaseapp.com",
  databaseURL: "https://precise-formula-426217-g5-default-rtdb.firebaseio.com",
  projectId: "precise-formula-426217-g5",
  storageBucket: "precise-formula-426217-g5.appspot.com",
  messagingSenderId: "16708100788",
  appId: "1:16708100788:web:357085373ed2183cfc2de1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export function writeUserData(key, session, content) {
    if(ENABLE_FIREBASE) {
        set(ref(db, 'sessions/' + session + '/' + key), content);
    }
}

