import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext } from "react";
import { RouteContext } from "../../providers/RouteProvider";
import { getCurrentPuzzle } from "../../lib/game-helpers";
import Sparkles from "../../../guess-connections/components/Sparkles";

function InfoModal({ isOpen, onClose }) {
  const { currentPuzzle } = useContext(RouteContext);
  const puzzle = getCurrentPuzzle({ puzzleId: currentPuzzle.puzzleId });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      scrollBehavior={"inside"}
      isCentered
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>
        <Sparkles>
        Puzzle {puzzle.puzzleId}. {puzzle.name}
        </Sparkles>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={10}>{puzzle.info}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} w="100%" size="lg">
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InfoModal;
