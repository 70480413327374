import { data as HEADLINES } from "./data";

export function getPuzzleData({ currentLevel }) {
  return HEADLINES.levels.find((x) => x.levelId === currentLevel);
}

export function isGuessCorrect({ gameData, guessCandidate }) {
  let level = gameData.find((x) => x.levelId === guessCandidate.levelId);
  let isYearCorrect = level.year === guessCandidate.year;
  let isMonthCorrect = level.month === guessCandidate.month;
  return {
    isYearCorrect,
    isMonthCorrect,
  };
}

export function checkSolution({ gameData, submittedGuesses, activeStep }) {
  let levelId = activeStep + 1;
  let guess = submittedGuesses.find((x) => x.levelId === levelId);
  let tagline = "";
  let color = "red";
  if (!guess) return {};
  let level = gameData.find((x) => x.levelId === levelId);
  let yearDifference = Math.abs(guess.year - level.year);
  let isMonthCorrect = yearDifference === 0 && guess.month === level.month;

  if (yearDifference === 0 && isMonthCorrect) {
    tagline = "You got both the year and month correct! Amazing!";
  } else if (yearDifference === 0) {
    tagline = "You got the year right! Sweet!";
  } else {
    tagline = `You were off by ${yearDifference} year${
      yearDifference > 1 ? "s" : ""
    }`;
  }
  if (yearDifference === 0) {
    color = "green";
  } else if (yearDifference === 1) {
    color = "teal";
  } else if (yearDifference >= 2 && yearDifference <= 5) {
    color = "orange";
  }
  return {
    guess,
    tagline,
    yearDifference,
    level,
    color,
  };
  // let isYearCorrect = level.year === guessCandidate.year;
  // let isMonthCorrect = level.month === guessCandidate.month;
  // return {
  //   isYearCorrect,
  //   isMonthCorrect
  // }
}

export function generateEmojiGridForHeadline({ solvedGameData }) {
  let emojiArray = [];
  for (var data of solvedGameData) {
    let level = "Headline " + data.guess.levelId;
    switch (data.color) {
      case "red":
        level += ` 🟥 (${data.yearDifference} away)`;
        break;
      case "orange":
        level += ` 🟧 (${data.yearDifference} away)`;
        break;
      case "teal":
        level += ` 🟩 (${data.yearDifference} away)`;
        break;
      default:
        if (data.level.month === data.guess.month) {
          level += ` ✅✅ (correct year and month!)`;
        } else {
          level += ` ✅ (correct year)`;
        }
        break;
       
    }
    emojiArray.push(level);
  }
  return emojiArray;
}

export function getHeadlinePoints({solvedGameData}) {
  let maxPoints = solvedGameData.length * 1250;
  let points = 0;
  for (var data of solvedGameData) {
    let pointsToAdd = 1000 - (data.yearDifference * 100);
    if(pointsToAdd < 0) pointsToAdd = 0;
    if(data.level.month === data.guess.month && data.level.year === data.guess.year) {
      pointsToAdd += 250;
    }
    points += pointsToAdd;
  }
  let title = "";
  if(points === 5000) {
    title = "Perfect!"
  } else if (points >= 3500 ) {
    title = "Well done!"
  } else if (points >= 2000 ) {
    title = "Not bad!"
  } else {
    title = "You tried..."
  }
  return {
    points, maxPoints, title
  }
}

export const puzzleAnswers = HEADLINES.levels;
