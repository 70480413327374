import { data as QUIZ } from "./data";

export function getPuzzleData({ currentLevel }) {
  return QUIZ.levels.find((x) => x.levelId === currentLevel);
}

export function isGuessCorrect({ gameData, guessCandidate }) {
  let level = gameData.find((x) => x.levelId === guessCandidate.levelId);
  let isYearCorrect = level.year === guessCandidate.year;
  let isMonthCorrect = level.month === guessCandidate.month;
  return {
    isYearCorrect,
    isMonthCorrect,
  };
}

export function checkSolution({ gameData, submittedGuesses, activeStep }) {
  let levelId = activeStep + 1;
  let guess = submittedGuesses.find((x) => x.levelId === levelId);
  let tagline = "";
  let color = "red";
  if (!guess) return {};
  let level = gameData.find((x) => x.levelId === levelId);
  let isCorrect = guess.answer.toUpperCase() === level.answer.toUpperCase();
  
  if (isCorrect) {
    color = "green";
  } else {
    color = "red";
  }
  return {
    guess,
    tagline,
    isCorrect,
    level,
    color,
  };
}

export function generateEmojiGridForQuiz({ solvedGameData }) {
  let emojiArray = [];
  for (var data of solvedGameData) {
    let level = "Question " + data.guess.levelId;
    switch (data.color) {
      case "red":
        level += ` 🟥`;
        break;
      default:
        level += ` ✅`;
        break;
       
    }
    emojiArray.push(level);
  }
  return emojiArray;
}

export function getQuizPoints({solvedGameData}) {
  let maxPoints = solvedGameData.length * 500;
  let points = 0;
  for (var data of solvedGameData) {
    if(data.isCorrect) points += 500
  }
  let title = "";
  if(points === 5000) {
    title = "Perfect!"
  } else if (points >= 3500 ) {
    title = "Well done!"
  } else if (points >= 2000 ) {
    title = "Not bad!"
  } else {
    title = "You tried..."
  }
  return {
    points, maxPoints, title
  }
}

export const puzzleAnswers = QUIZ.levels;
