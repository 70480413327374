import { ListItem, UnorderedList } from "@chakra-ui/react";

function QuizInfo() {
  return (
    <>
      <br />
      Remember when I asked you to pay attention to EVERYTHING? Let's see if you were <i>actually</i> paying attention...
      <br />
      <br />
      This last puzzle is actually a quiz! Don't worry, it's only 10 questions and all of them are related to this very game, so you're gonna do great... right???
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>Read the question</ListItem>
        <ListItem>Click on the option you think is the answer</ListItem>
        <ListItem>Click Submit to make a guess</ListItem>
      </UnorderedList>
      <br />
      After each guess I will reveal the correct answer. You get 500 points for each correct guess. What's the point of points, you ask? Idk, but they're cool
      <br />
    </>
  );
}

export default QuizInfo;
