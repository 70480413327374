import { getGameDate } from './time-utils';
import {
  formatISO
} from "date-fns";
import CryptoJS from "crypto-js";
import { writeUserData } from '../../common/lib/firebase';
const gameStateKey = "GUESS_CONNECTIONS_DATA";

const PASSPHRASE = "JORDANISTHEGOAT"

const encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, PASSPHRASE)
}

const decrypt = (data) => {
  const decrypted = CryptoJS.AES.decrypt(data, PASSPHRASE)
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const saveGameStateToLocalStorage = (gameState, session = 1) => {
  localStorage.setItem(gameStateKey, encrypt(JSON.stringify(gameState)));
  try{
    writeUserData(gameStateKey, session, gameState)
  } catch {
    
  }
  
};

export const loadGameStateFromLocalStorage = () => {
  stateMigration();
  const state = localStorage.getItem(gameStateKey);
  return state ? JSON.parse(decrypt(state)) : null;
};

const gameStatKey = "gameStats";

export const saveStatsToLocalStorage = (gameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
};

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey);
  return stats ? JSON.parse(stats) : null;
};

export const stateMigration = () => {
  const state = localStorage.getItem("gameState");
  if(state) {
    saveGameStateToLocalStorage(JSON.parse(state));
    localStorage.removeItem("gameState");
  }
}
