import { ListItem, UnorderedList } from "@chakra-ui/react";

function GeoguessrInfo() {
  return (
    <>
      <br />
      If there's one thing I know about you is how much you loooove your country. You know every corner of it like the back of your hand... or do you?
      <br />
      <br />
      I'm bringing Geoguessr back! For this puzzle, I wanna test how well you know the land of the free. You
      will spawn at a not-so-random location in the United States. You just have
      to guess which state you spawned in.
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>
          Navigate through Google Street View until you find out the state
          you’re in (there’s no time limit!)
        </ListItem>
        <ListItem>
          Make a guess by clicking on a state using the map and then click
          Submit.
        </ListItem>
        <ListItem>
          Hit a dead end? I added a “Go back to spawn” button for your
          convenience (bottom left corner)
        </ListItem>
      </UnorderedList>
      <br />
      After each guess I will reveal the correct answer and the exact location
      on the map. You will be awarded points based on whether you were right or
      wrong.
      <br />
      <br />
      <i>If at any point you are completely stomped, just remember who made this puzzle</i>
    </>
  );
}

export default GeoguessrInfo;
