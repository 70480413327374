export const shareStatus = (
  title,
  content,
  points,
  handleShareToClipboard,
  handleShareFailure
) => {
  const textToShare = title + "\n\n" + content.join("\n") + "\n\n" + points
  let shareSuccess = false;

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure);
      } else {
        handleShareFailure();
      }
    }
  } catch (error) {
    handleShareFailure();
  }
};

