import { InfoIcon, MoonIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Show,
  Spacer,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import "./header.css";
import { useContext, useEffect } from "react";
import { RouteContext } from "../../providers/RouteProvider";
import InfoModal from "../InfoModal";

function Header() {
  const { toggleColorMode } = useColorMode();
  const { currentPuzzle, closeInfoModal } = useContext(RouteContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (
      currentPuzzle.status === "INTRO" &&
      !currentPuzzle.loading &&
      currentPuzzle.puzzleId !== -1 &&
      currentPuzzle.puzzleId !== 0 &&
      currentPuzzle.puzzleId !== 8
    ) {
      onOpen();
    }
  }, [currentPuzzle]);

  const handleClose = () => {
    closeInfoModal();
    onClose();
  };

  return (
    <>
      <Flex
        as={"header"}
        alignItems="center"
        gap="2"
        className={
          "justify-between p-5 " +
          (currentPuzzle?.puzzleId === 2 ? "is-second-puzzle " : "") +
          (currentPuzzle?.puzzleId === -1 ||
          currentPuzzle?.puzzleId === 0 ||
          currentPuzzle?.puzzleId === 8
            ? "is-intro-or-end "
            : "") +
          (currentPuzzle?.loading ? "is-loading " : "")
        }
        style={{ width: "100%" }}
      >
        <Show breakpoint="(min-width: 460px)">
          <Spacer />
        </Show>
        <Heading as={"h1"} size={["md", "xl"]}>
          Puzzle Pathway
        </Heading>
        <Box flex={1} textAlign={"right"}>
          <Button
            mr={3}
            className="infoButton"
            onClick={onOpen}
          >
            <InfoIcon />
          </Button>
          <Button onClick={toggleColorMode}>
            <MoonIcon />
          </Button>
        </Box>
        <InfoModal isOpen={isOpen} onClose={handleClose} />
      </Flex>
    </>
  );
}

export default Header;
