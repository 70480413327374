import CryptoJS from "crypto-js";
import { writeUserData } from "./firebase";
const PASSPHRASE = "jordanIsHotAsFuk"
const encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, PASSPHRASE)
}

const decrypt = (data) => {
  const decrypted = CryptoJS.AES.decrypt(data, PASSPHRASE)
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const saveGameStateToLocalStorage = (gameStateKey, gameState, session = 1) => {
  const data = encrypt(JSON.stringify(gameState))
    localStorage.setItem(gameStateKey, data);
    try {
      writeUserData(gameStateKey, session, gameState)
    } catch {

    }
  };

  export const loadGameStateFromLocalStorage = (gameStateKey) => {
    const state = localStorage.getItem(gameStateKey);
    return state ? JSON.parse(decrypt(state)) : null;
  };