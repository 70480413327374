import { ListItem, UnorderedList } from "@chakra-ui/react";

function ConnectionsInfo() {
  return (
    <>
      <br />
      Obviously I had to throw in a Connections game for the #1 Connections player (and maker!)
      <br />
      <br />
      For this puzzle, you're just solving a good ol’ Connections puzzle. Nothing out of the ordinary here.
      <br />
      <br />
      <b>How to play (like you didn't know already)</b>
      <UnorderedList>
        <ListItem>
        	Select four items and tap 'Submit' to check if your guess is correct.
        </ListItem>
        <ListItem>
        	Find all 4 groups!
        </ListItem>
      </UnorderedList>
      <br />
      <i>Since it’s your birthday, you can make up to 24 mistakes ;)</i>
    </>
  );
}

export default ConnectionsInfo;
