export const data = {
    levels: [
      {
        levelId: 1,
        word: "BORN",
        file: "1.mp3"
      },
      {
        levelId: 2,
        word: "PILCROW",
        file: "2.mp3"
      },
      {
        levelId: 3,
        word: "VACUOUSNESS",
        file: "3.mp3"
      },
      {
        levelId: 4,
        word: "OEUVRE",
        file: "4.mp3"
      },
    ],
  }