import { data as MAPS } from "./data";

export function getPuzzleData({ currentLevel }) {
  return MAPS.levels.find((x) => x.levelId === currentLevel);
}

export function isGuessCorrect({ gameData, guessCandidate }) {
  let level = gameData.find((x) => x.levelId === guessCandidate.levelId);
  let isYearCorrect = level.year === guessCandidate.year;
  let isMonthCorrect = level.month === guessCandidate.month;
  return {
    isYearCorrect,
    isMonthCorrect,
  };
}

export function checkSolution({ gameData, submittedGuesses, activeStep }) {
  let levelId = activeStep + 1;
  let guess = submittedGuesses.find((x) => x.levelId === levelId);
  let tagline = "";
  let color = "red";
  if (!guess) return {};
  let level = gameData.find((x) => x.levelId === levelId);
  let isCorrect = guess.map.toUpperCase() === level.map.toUpperCase();
  let distance = calcDistance(guess.coordinates.top, guess.coordinates.left, level.coordinates.top, level.coordinates.left);
  let mapPoints = 1250;
  if(!isCorrect) {
    mapPoints = 0;
    tagline = "Wrong map! You got 0 / 1250 points for this guess"
  } else {
    if(distance > 3) {
      mapPoints -= Math.round(distance * 10)
    }
    if(mapPoints === 1250) {
      tagline = "Right on the money! You got 1250 / 1250 points for this guess!"
    } else {
      tagline = `You got ${mapPoints} / 1250 points for this guess`
    }
  }
  
  if(mapPoints >= 1200) {
    color = "green";
  } else if(mapPoints > 0) {
    color = "orange";
  } else {
    color = "red";
  }
  return {
    guess,
    tagline,
    isCorrect,
    level,
    color,
    mapPoints
  };
}

export function generateEmojiGridForHeadline({ solvedGameData }) {
  let emojiArray = [];
  for (var data of solvedGameData) {
    let level = "Map " + data.guess.levelId;
    switch (data.color) {
      case "red":
        level += ` 🟥 (${data.mapPoints})`;
        break;
      default:
        if(data.mapPoints === 1250) {
          level += ` ✅ (${data.mapPoints})`;
        } else if(data.mapPoints >= 1200) {
          level += ` 🟩 (${data.mapPoints})`;
        } else {
          level += ` 🟧 (${data.mapPoints})`;
        }
        break;
       
    }
    emojiArray.push(level);
  }
  return emojiArray;
}

export function getMapsPoints({solvedGameData}) {
  let maxPoints = solvedGameData.length * 1250;
  let points = 0;
  for (var data of solvedGameData) {
    points += data.mapPoints
  }
  let title = "";
  if(points === 5000) {
    title = "Perfect!"
  } else if (points >= 3500 ) {
    title = "Well done!"
  } else if (points >= 2000 ) {
    title = "Not bad!"
  } else {
    title = "You tried..."
  }
  return {
    points, maxPoints, title
  }
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function calcDistance(x1, y1, x2, y2) 
{
  var a = x1 - x2;
  var b = y1 - y2;
  
  var c = Math.sqrt( a*a + b*b );
  return c;
}

export const puzzleAnswers = MAPS.levels;
