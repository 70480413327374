import { createContext, useEffect, useState } from "react";
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from "../../lib/local-storage";
import { OVERRIDE_ROUTE, PUZZLE_ROUTE } from "../../lib/constants";
import { newGuid, smileyFace, validateToken, validateUsername } from "../../lib/security";
export const RouteContext = createContext();

function RouteProvider({ children }) {
  const [selectedPuzzle, setSelectedPuzzle] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState(null);
  const [smiley, setSmiley] = useState({});
  const [currentPuzzle, setCurrentPuzzle] = useState(() => {
    if (OVERRIDE_ROUTE) {
      return {
        puzzleId: 6,
        status: "IN PROGRESS",
        loading: true,
        token: null,
      };
    }

    const loadedState = loadGameStateFromLocalStorage(PUZZLE_ROUTE);
    let isValid = false;
    if (loadedState?.currentPuzzle !== undefined) {
      isValid = validateToken({ token: loadedState.currentPuzzle.token });
      if (!isValid) {
        // setTimeout(() => {
        //   window.history.pushState({}, document.title, window.location.pathname);
        // }, 1000)
        return {
          puzzleId: -1,
          status: "INTRO",
          loading: true,
          token: loadedState.currentPuzzle.token,
        };
      }
      // setTimeout(() => {
      //   window.history.pushState({}, document.title, window.location.pathname);
      // }, 1000)
      return loadedState.currentPuzzle;
    }
    let searchParams = new URL(document.location.toString()).searchParams;
    let token = searchParams.get("token");
    isValid = validateToken({ token });
    if (!isValid) {
      // setTimeout(() => {
      //   window.history.pushState({}, document.title, window.location.pathname);
      // }, 1000)
      return {
        puzzleId: -1,
        status: "INTRO",
        loading: true,
        token,
      };
    }
    // setTimeout(() => {
    //   window.history.pushState({}, document.title, window.location.pathname);
    // }, 1000)
    return {
      puzzleId: 0,
      status: "INTRO",
      loading: true,
      session: newGuid(),
      token,
    };
  });
  useEffect(() => {
    async function getData() {
      const fetchedData = await smileyFace();
      setSmiley(fetchedData);
    }
    getData();
  }, []);

  useEffect(() => {
    setCurrentPuzzle({...currentPuzzle, smiley: smiley})
  }, [smiley]);

  const nextPuzzle = function () {
    const puzzle = currentPuzzle.puzzleId;
    setCurrentPuzzle({
      ...currentPuzzle,
      puzzleId: puzzle + 1,
      status: "INTRO",
      loading: true,
    });
  };

  const stopLoading = function () {
    setCurrentPuzzle({ ...currentPuzzle, loading: false });
  };

  const closeInfoModal = () => {
    if (currentPuzzle.status === "INTRO") {
      setCurrentPuzzle({ ...currentPuzzle, status: "IN PROGRESS" });
    }
  };

  useEffect(
    function () {
      if (currentPuzzle.puzzleId !== -1 && !OVERRIDE_ROUTE) {
        saveGameStateToLocalStorage(PUZZLE_ROUTE, {
          currentPuzzle: { ...currentPuzzle, loading: true },
        }, currentPuzzle.session);
      }
    },
    [currentPuzzle]
  );

  useEffect(
    function() {
      setCode(validateUsername({username}))
    },
    [username]
  )

  return (
    <RouteContext.Provider
      value={{
        currentPuzzle,
        nextPuzzle,
        stopLoading,
        closeInfoModal,
        selectedPuzzle,
        setSelectedPuzzle,
        isSaving,
        setIsSaving,
        username,
        setUsername,
        code
      }}
    >
      {children}
    </RouteContext.Provider>
  );
}

export default RouteProvider;
