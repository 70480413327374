import { Button, Heading, Stack, useToast } from "@chakra-ui/react";
import React from "react";
import "./connectionsResults.css";
import { generateEmojiGrid } from "../../lib/game-helpers";
import { motion, useIsPresent } from "framer-motion"
import { RouteContext } from "../../../common/providers/RouteProvider";
import { GameStatusContext } from "../../providers/GameStatusProvider";
import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { shareStatus } from "../../../common/lib/share-game";

function ConnectionsResults({ close, isFinal }) {
    const {
        nextPuzzle
      } = React.useContext(RouteContext);
      const isPresent = useIsPresent();
      const { submittedGuesses } =
      React.useContext(GameStatusContext);
    const { gameData } = React.useContext(PuzzleDataContext);
  const toast = useToast();
  function handleShareToClipboard() {
    toast({
      title: "Copied to clipboard",
      description: "But why?",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  }
  function handleShareFailure() {
    toast({
      title: "Could not copy to clipboard",
      description: "Please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  }
  const copyToClipboard = () => {
    shareStatus(
      "Connections",
      generateEmojiGrid(gameData, submittedGuesses).split(","),
      "",
      handleShareToClipboard,
      handleShareFailure
    );
  };
  const handleNextPuzzle = () => {
    if(typeof close === "function") {
      close();
    }
    nextPuzzle();
  }
  return (
    <div className="results-screen">
      <Heading mb="19">Well done!</Heading>
      <Heading size={"md"}>Connections</Heading><br/>
      <span className="text-center whitespace-pre mb-2">
            {"\n"}
            {generateEmojiGrid(gameData, submittedGuesses)}
          </span>
      {
         //"Total score: " + points + " / " + maxPoints
      }
      <Stack direction={["column", "row"]} pt="10">
        <Button
          w="100%"
          onClick={copyToClipboard}
          colorScheme="white"
          variant="outline"
          size="lg"
        >
          Copy to clipboard
        </Button>
        {!isFinal && (
          <Button
            w="100%"
            onClick={handleNextPuzzle}
            colorScheme="white"
            variant="outline"
            size="lg"
          >
            Next puzzle
          </Button>
        )}
        </Stack>
    </div>
  );
}

export default ConnectionsResults;
