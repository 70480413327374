export const data = {
  levels: [
    {
      levelId: 1,
      question:
        "According to Headline #4, who declared himself the winner of the 1986 Dominican presidential election despite being 35,000 votes behind?",
      choices: [
        {
          id: "A",
          name: "Joaquin Balaguer",
        },
        {
          id: "B",
          name: "Jacobo Majluta Azar",
        },
        {
          id: "C",
          name: "Jacinto Peynado",
        },
        {
          id: "D",
          name: "Juan Pablo Duarte",
        },
      ],
      answer: "B",
      correctTagline: "Correct! He ended up losing the election and refused to concede, claiming the election was rigged. Sounds familiar?",
      wrongTagline: "Wrong! Majluta claimed to be the winner but ended up losing the election and refused to concede, claiming the election was rigged. Sounds familiar?"
    },
    {
      levelId: 2,
      question: "On the U.S. states puzzle, you deeeefinitely noticed there was only 49 states on the map. Which state was missing?",
      choices: [
        {
          id: "A",
          name: "Ohio",
        },
        {
          id: "B",
          name: "Vermont",
        },
        {
          id: "C",
          name: "Missouri",
        },
        {
          id: "D",
          name: "Louisiana",
        },
      ],
      answer: "A",
      correctTagline: "Amazing! Now please be a skibidi sigma and stick up your gyatt for the rizzler",
      wrongTagline: "What the skibidi sigma? You can't stick up your gyatt for the rizzler with that L performance"
    },
    {
      levelId: 3,
      question: "Which agent did I use to take the screenshot on Ascent?",
      choices: [
        {
          id: "A",
          name: "Chamber",
        },
        {
          id: "B",
          name: "Clove",
        },
        {
          id: "C",
          name: "Reyna",
        },
        {
          id: "D",
          name: "Gekko",
        },
      ],
      answer: "A",
      correctTagline: "Bravo!",
      wrongTagline: "As Chamber would say: bien essayé"
    },
    {
      levelId: 4,
      question: "Whose brain was referred to as vacuous during the spelling bee puzzle?",
      clarification: "Hint: it's a Spongebob character!",
      choices: [
        {
          id: "A",
          name: "Mr. Krabs",
        },
        {
          id: "B",
          name: "Squidward",
        },
        {
          id: "C",
          name: "Patrick",
        },
        {
          id: "D",
          name: "Spongebob",
        },
      ],
      answer: "D",
      correctTagline: "His brain might be vacuous, but yours clearly isn't. Keep it up!",
      wrongTagline: "Nice try..."
    },
    {
      levelId: 5,
      question: "On which puzzle did I swap the infinity looking thingymabobber for Lightning McQueen?",
      clarification: "The small spinning logo that pops up on the top left corner whenever the app saves your progress",
      choices: [
        {
          id: "A",
          name: "Spelling bee",
        },
        {
          id: "B",
          name: "U.S. states",
        },
        {
          id: "C",
          name: "Valo Guesser",
        },
        {
          id: "D",
          name: "Connections",
        },
      ],
      answer: "D",
      correctTagline: "Kachow!",
      wrongTagline: "Nah-chow!"
    },
    {
      levelId: 6,
      question: "According to the recipe and picture, what is the correct order of the balls on a dango?",
      clarification: "From top to bottom, as if you're holding an already assembled skewer up straight",
      choices: [
        {
          id: "A",
          name: "Pink, white, green",
        },
        {
          id: "B",
          name: "Pink, green, white",
        },
        {
          id: "C",
          name: "Green, white, pink",
        },
        {
          id: "D",
          name: "White, pink, green",
        },
      ],
      answer: "A",
      correctTagline: "Right! This order is said to represent the order in which cherry blossoms bloom. Thought you might find that interesting because... well... you know",
      wrongTagline: "Wrong! This order is said to represent the order in which cherry blossoms bloom. Thought you might find that interesting because... well... you know"
    },
    {
      levelId: 7,
      question: "During the spelling bee, president Biden famously said 'even though I was born a long long time ago...'",
      choices: [
        {
          id: "A",
          name: "'...I will kick Trump's butt'",
        },
        {
          id: "B",
          name: "'...I won't drop out of the race'",
        },
        {
          id: "C",
          name: "'...I still have that dog in me'",
        },
        {
          id: "D",
          name: "'...I need to take a nap'",
        },
      ],
      answer: "C",
      correctTagline: "Great job! I knew you had that dog in you!",
      wrongTagline: "It's joever..."
    },
    {
      levelId: 8,
      question: "Which of the following weapons was not featured on the Connections game?",
      clarification: "Regardless of whether it was a part of the Weapons category",
      choices: [
        {
          id: "A",
          name: "Sword",
        },
        {
          id: "B",
          name: "Dagger",
        },
        {
          id: "C",
          name: "Club",
        },
        {
          id: "D",
          name: "Staff",
        },
      ],
      answer: "A",
      correctTagline: "You got it right! You took a stab at it and it worked (get it?)",
      wrongTagline: "Well, at least you took a stab at it (get it?)"
    },
    {
      levelId: 9,
      question: "What was the second question of this quiz?",
      choices: [
        {
          id: "A",
          name: "Something about presidents?",
        },
        {
          id: "B",
          name: "Something about a Valorant agent?",
        },
        {
          id: "C",
          name: "Something about a state missing?",
        },
        {
          id: "D",
          name: "Something about Spongebob?",
        },
      ],
      answer: "C",
      correctTagline: "Ok just making sure you're still paying attention",
      wrongTagline: "BROOOO I thought we'd established you have to pay attention at all times!"
    },
    {
      levelId: 10,
      question: "If you combine the first answer/category from each puzzle, you get the phrase '1997 Washington born White Lotus blondie'. Which of the following actresses fits that description?",
      choices: [
        {
          id: "A",
          name: "Sydney Sweeney",
        },
        {
          id: "B",
          name: "Sabrina Carpenter",
        },
        {
          id: "C",
          name: "Elle Fanning",
        },
        {
          id: "D",
          name: "Margot Robbie",
        },
      ],
      answer: "A",
      correctTagline: "LET'S GO! I thought I was overcooking with this but thank God you got it",
      wrongTagline: "Tbh I don't blame you, this was just me overcooking"
    },
  ],
};
