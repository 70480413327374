import { ListItem, UnorderedList } from "@chakra-ui/react";

function SpellingBeeInfo() {
  return (
    <>
      <br />
      You have such a way with words, but can you spell those words correctly?
      <br />
      <br />
      This is a spelling bee! For this puzzle, you will be presented with 4 audio “recordings”. Just listen to them and spell the respective word.
      <br />
      <br />
      <b>How to play</b>
      <UnorderedList>
        <ListItem>
        Listen to each audio carefully (you can replay it as many times as you want)
        </ListItem>
        <ListItem>
        	Type in the textbox how you think the word is spelled
        </ListItem>
        <ListItem>
        	Click Submit to commit your guess
        </ListItem>
      </UnorderedList>
      <br />
      After each guess I will reveal the correct answer. You will be awarded points based on whether you were right or wrong.
      <br />
      <br />
      I was gonna record these myself, <i>but then I had a better idea...</i>
    </>
  );
}

export default SpellingBeeInfo;
